<template>
	<div class="login-container">
		<div class="backBox">
			<div class="conDiv">
				<div class="loginBox">
					<!-- <div class="logo" v-if="!googleCode">
						<img class='logoImg' src="../../assets/image/logo.jpg" />
					</div> -->
					<div class="loginContent mb20">
						<div class="mr40">
							<div class="logo" v-if="$PayNameImg == 'laike'">
								<img class='logoImg' src="../../assets/image/logo.jpg" />
							</div>
							<div class="logo" v-else-if="$PayNameImg == 'tianrong'">
								<img class='logoImg' src="../../assets/image/tr.jpg" />
							</div>
							<div class="logo" v-else-if="$PayNameImg == 'bitong'">
								<img class='logoImg' src="../../assets/image/bitong.jpg" />
							</div>
							<div class="logo" v-else-if="$PayNameImg == 'aipay'">
								<img class='logoImg' src="../../assets/image/aipay.jpg" />
							</div>
							<div class="logo" v-else>
								<img class='logoImg' src="../../assets/image/commonLogo.jpg" />
							</div>
						</div>
						<div class="guli">
							<span>后台管理系统</span>
						</div>
					</div>
					<!-- <div class="loginTitle" v-if="!googleCode">登 录</div> -->
					<div class="loginTitle" v-if="googleCode">谷歌验证</div>
					<span><el-input size="mini" autocomplete="new-password" class="loginInput" prefix-icon="el-icon-user" placeholder="请输入用户名" v-model="username" clearable></el-input></span>
					<span><el-input size="mini" autocomplete="new-password" class="mt20 loginInput" prefix-icon="el-icon-lock" placeholder="请输入密码" v-model="password" show-password></el-input></span>
					<span><el-input size="mini" class="mt20 loginInput" prefix-icon="el-icon-key" placeholder="请输入谷歌验证码" v-model="code" clearable v-if="!googleCode"></el-input></span>
					<span><el-button @click="login()" size="mini" class="mt20" type="primary" style="width: 100%;" v-if="!googleCode" :disabled="loading">登 录</el-button></span>
					<span><el-button size="mini" class="mt20" type="primary" style="width: 100%;" @click="toGoogleCode()" v-if="!googleCode">谷歌绑定</el-button></span>
					<span><el-button size="mini" class="mt20" type="primary" style="width: 100%;" @click="validateGcode()"v-if="googleCode">下一步</el-button></span>
					
				</div>
			</div>
			
			<div class="footer"><span>copyright ©2024</span></div>
		</div>
		<el-dialog class="dialog" top="200px" title="验证码" :visible.sync="dialogVisible" width="400px">
			<div class="qrcode">
				<img :src="qrcode" alt="">
			</div>
		</el-dialog>
	</div>
</template>

<script>
	// import QRCode from 'qrcodejs2'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	import local from '../../util/local.js'
	export default {
		name: 'Login',
		data() {
			return {
				username:'',
				password:'',
				code:'',
				dialogVisible: false,
				qrcode:'',
				googleCode:false,
				loading:false
			}
		},
		created() {
			
		},
		mounted(){
			let self = this
			document.onkeydown = function(e) {
			  let ev = document.all ? window.event : e
			  if (ev.keyCode === 13) {
				  if(!self.loading){
					  self.login()
				  }
				
			  }
			}
		},
		destroyed() {
			document.onkeydown = null
		},
		watch: {},
		methods: {
			
			login(){
				if(isEmpty(this.username,"请输入账号")){
					return
				}
				if(isEmpty(this.password,"请输入密码")){
					return
				}
				if(isEmpty(this.code,"请输入谷歌验证码")){
					return
				}
				let data={
					username:this.username,
					password:this.$md5(this.password) ,
					code:this.code,
				}
				this.loading = true
				this.$api.login(data).then(res => {
					this.loading = false
					if(res.status === 1){
						let data = res.data	
						let ritun = data.tiRun
						let manage = data.manage
						// this.cardDealstate();
						this.$local.set('fourusername',data.username);
						this.$local.set('fourtid',data.tid);
						this.$local.set('tiRun',ritun);
						this.$local.set('manage',manage);
						
						this.$router.push('./index')
						this.$router.options.routes.forEach(item=>{
							if (item.path =='/userSys'||item.path =='/characterSys') {
								item.directory = manage
							}
						})
						this.$parent.getNav()	
					}else{
						this.$message({
						  type: 'info',
						  message: res.msg
						}); 
					}
					// console.log(res)
				}).catch(error=>{
					this.loading = false
				})
			},
			close(){
				
			},
			validateGcode(){
				if(isEmpty(this.username,"请输入账号")){
					return
				}
				if(isEmpty(this.password,"请输入密码")){
					return
				}
				let data={
					username:this.username,
					password:this.$md5(this.password)
				}
				
				this.$api.validateGcode(data).then(res=>{
					// console.log(res)
					//1 成功 未绑定 3已绑定   2未登录  其他失败
					if(res.status === 1){
						local.set('googleInfo',res.data)
						this.$router.push({path:"/googleVerification"})
					}else if(res.status == 3){
						this.googleCode = false
						this.$message({
						  type: 'info',
						  message: res.msg
						});    
					}else{
						this.$message({
						  type: 'info',
						  message: res.msg
						});    
					}
				})
			},
			toGoogleCode(){
				this.googleCode=true;
			},
			//银行卡状态
			cardDealstate() {
				this.$api.cardDealstate({}).then(res => {
					if (res.status == 1) {
						// this.bankState = res.data
						this.$local.set('bankState',res.data)
						// for (let i in this.bankState) {
						// 	this.state = i;
						// 	break;
						// }
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
		
		}
	}
</script>
<style>
	.loginInput .el-input__inner{
		/* background: transparent;
		color: #232323;
		border: none;
		border-bottom: solid 1px #ddd;
		border-radius: 3px; */
	}
</style>
<style lang="scss" scoped>
	.login-container {
		// height: 100vh;
		// display: flex;
		// align-items: center;
		// justify-content: center;
		// background: url(../../assets/image/bg.png) no-repeat;
		background: url(../../assets/image/loginbg.svg) no-repeat;
		background-size: cover;
		.backBox{
			height: 100vh;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			// background-color: rgba(0,0,0,0.3);
		}
		.loginTitle{
			font-size: 20px;
			text-align: center;
			margin-bottom: 40px;
			color: #000000;
			background: url(../../assets/image/logo.jpg) no-repeat;
			background-size: cover;
		}
		.conDiv{
			// height: 85%;
			// padding-bottom: 300px;
			margin-bottom: 100px;
			border-radius: 12px;
		}
		.loginBox{
			border-radius: 3px;
			background-color: rgba(255,255,255,1);
			padding: 40px 20px;
			width: 23.125rem;
			// height: 26.375rem;
			// padding-bottom: 100px;
			box-shadow: 0 0 5px 1px rgba(0,0,0,0.2);	
			
			.logo{
				// width: 60%;
				// height: 20%;
				.logoImg{
					height: 77px;
					width: 77px;
					text-align: center;
					border-radius: 18px;
				}
			}		
		}
		
	}
	.qrcode{
	    display: inline-block;
		width: 100%;
		height: 100%;
	    img {
			width: 100%;
	        background-color: #fff; //设置白色背景色
	        box-sizing: border-box;
	    }
	}
	.dialog{
		// transform: translate(0,25%);
	}
	.guli {
	  display: flex;            /* 启用 Flexbox 布局 */
	  justify-content: center;  /* 水平方向居中 */
	  align-items: center;      /* 垂直方向居中 */
	  span {
	    font-size: 1.45rem;          /* 根据需要调整文字大小 */
	  }
	}
	
	.loginContent{
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
