export const main = {
	language:'中文',
	header:{
		'allData':'数据总览',
		'agentCanUseBalance':'代理可用余额',
		'agentFrozenBalance':'代理冻结余额',
		'traderCanUseBalance':'商户可用余额',
		'traderFrozenBalance':'商户冻结余额',
		'platformLubrication':'平台提润',
		'accountNumber':'账号',
		'logOut':'退出登录'
	},
	menu:{
		'reportManagement':'报表管理',
		'generalStatement':'后台报表管理',
		'dsReport':'代收报表',
		'dfReport':'代付报表',
		'agentIncome':'代理进账报表',
		'merchantIncome':'商家进账报表',
		'merchantRate':'商家费率报表',
		'collectionMethod':'代收方式报表',
		'expenditureStatement':'商家代付报表',
		'passagewayStatement':'通道报表',
		'passagewayManagement':'通道管理',
		'currencyManagement':'币种管理',
		'collectionMethodManagement':'代收付管理',
		'accountManagement':'账号管理',
		'agentManagement':'代理管理',
		'merchantManagement':'商户管理',
		'merchantMoneyChange':'商户账变表',
		'agentMoneyChange':'代理账变表',
		'orderManagement':'代收订单',
		'payOrderManagement':'话单',
		'systemSettings':'系统设置',
		'passagewaySystemManagement':'通道系统管理',
		'collectionOrder':'全部订单',
		'abOrder':'异常订单',
		'offlineDepositOrder':'线下入金订单',
		'paymentOrder':'全部订单',
		'platformLubricationOrder':'平台提润订单',
		'agentLubricationOrder':'代理提润订单',
		'supplementOrder':'补增代收订单',
		'supplementOrderPay':'补增代付订单',
		'blacklistManagement':'黑名单管理',
		'changePassword':'修改密码',
		'revokeOrder':'撤销订单',
		'supplementRecord':'补单记录',
		'transferPlatformProfit':'平台利润划转',
		'platformLubrication':'平台提润',
		'userManagement':'用户管理',
		'roleManagement':'角色管理',
		'operationLog':'操作日志',
		'allData':'数据总览',
		'telegram':'Telegram',
		'botManagement': '机器人配置',
		'merchantChatManagement': '商户群',
		'passagewayChatManagement': '通道群',
		'noticeManagement': '群发消息',
	},
	reportMenu:{
		'theDayBefore':'前一天',
		'theNextDay':'后一天',
		'collectionMethod':'代收方式',
		'query':'查询',
		'serialNumber':'序号',
		'agent':'代理',
		'agentRemarks':'代理备注',
		'numberOfMerchants':'商家数',
		'submit':'提交',
		'success':'成功',
		'successRate':'成功率',
		'numberOfOrder':'单数',
		'amount':'金额',
		'passageway':'通道',
		'platform':'平台',
		'business':'商家',
		'totalAmount':'总金额',
		'to':'至',
		'pleaseChoose':'请选择',
		'PleaseEnterTheAgentAccount':'请输入代理账号',
		'PleaseEnterTheBusinessAccount':'请输入商家账号',
	},
	passagewayMenu:{
		'add':'新 增',
		'addCurrency':'添加币种',
		'currencyName':'币种名称',
		'currencyAbbreviation':'币种简称',
		'exchangeRate':'汇率(即 1 USDT兑换的比率)',
		'operation':'操作',
		'setExchangeRate':'设置汇率',
		'collectionMethod':'代收方式',
		'paymentMethod':'代付方式',
		'state':'状态',
		'enable':'启用',
		'disable':'停用',
		'passagewayName':'通道名称',
		'balance':'余额',
		'mainSwitch':'总开关',
		'collectionSwitch':'代收开关',
		'paymentSwitch':'代付开关',
		'open':'开启',
		'close':'关闭',
		'commissionCollection':'代收手续费',
		'commissionPayment':'代付手续费',
		'setLimits':'设置限额',
		'rate':'费率',
		'minimum':'最小值',
		'maximum':'最大值',
		'singleCost':'单笔费用',
		'singleLimit':'单笔限额',
	},
	accountMenu:{
		'add':'新 增',
		'agentName':'代理名称',
		'remarks':'备注',
		'totalAmount':'总余额',
		'balanceOfEachAccount':'各账户余额',
		'frozenBalance':'冻结余额',
		'frozenBalanceOfEachAccount':'各账户冻结余额',
		'state':'状态',
		'function':'功能',
		'enable':'启用',
		'disable':'停用',
		'edit':'编辑账户',
		'commissionCollection':'代收手续费',
		'commissionPayment':'代付手续费',
		'offlineDepositHandlingFee':'线下入金手续费',
		'resetAgentPassword':'重置代理密码',
		'addSubordinateAgent':'新增下级代理',
		'total':'总计',
		'serialNumber':'序号',
		'superiorAccount':'上级账号',
		'merchantName':'商家名称',
		'merchantAccount':'商户账号(登录)',
		'merchantNumber':'商家号',
		'resetMerchantPassword':'重置商家密码',
		'resetGoogleVerification':'重置谷歌验证',
		'setLimits':'设置限额',
		'freezeMerchantBalance':'冻结商家余额',
		'unfreezeMerchantBalance':'解冻商家余额',
		'enableMerchant':'启用商家',
		'disableMerchant':'禁用商家',
		'passagewayConfiguration':'通道配置',
		'passagewayProportionalConfiguration':'通道比例配置',
		'setPaymentPassword':'设置代付密码',
		'actionLog':'查看日志',
		'whiteList':'设白名单',
		'balanceRetentionCap':'余额留存上限',
		'nameMatchingPattern':'姓名匹配模式',
		'bindMerchantCurrency':'绑定商户币种',
		'totalOfCurrencyPage':'当页总计',
		'noData':'暂无数据',
	},
	orderMenu:{
		'theDayBefore':'前一天',
		'theNextDay':'后一天',
		'pleaseEnterTheOrderNumber':'请输入订单号',
		'pleaseEnterTransactionNo':'请输入交易单号',
		'pleaseEnterTheMerchantNumber':'请输入商家号',
		'pleaseSelect':'请选择',
		'pleaseEnterTheMerchantName':'请输入商家名称',
		'pleaseEnterTheNameOfTheBankCardHolder': '请输入银行卡持有人名称',
		'pleaseEnterTheSubmittedAmount':'请输入提交金额',
		'pleaseEnterIp':'请输入IP',
		'PleaseEnterThePayerName':'请输入付款人姓名',
		'to':'至',
		'pleaseSelectCollectionMethod':'请选择代收方式',
		'pleaseSelectPassageway':'请选择通道',
		'pleaseSelectState':'请选择状态',
		'query':'查询',
		'exportExcel':'导出Excel',
		'orderNumber':'订单编号',
		'transactionNo':'交易号',
		'merchant':'商家',
		'passageway':'通道',
		'collectionMethod':'代收方式',
		'paymentMethod':'代付方式',
		'orderAmount':'订单金额',
		'actualAmount':'实际金额',
		'currency':'币种',
		'orderState':'订单状态',
		'commission':'手续费',
		'ActualArrival':'实际到账',
		'submissionTime':'提交时间',
		'nameOfPayer':'付款人',
		'process':'审核',
		'processingTime':'处理时间',
		'pleaseSelectCurrency':'请选择币种',
		'sendCallback':'发送回调',
		'supplement':'补单',
		'handlingRate':'手续费率',
		'singleHandlingCharge':'单笔手续费',
		'transactionInformation':'交易信息',
		'userType':'用户类型',
		'userInformation':'用户信息',
		'paymentInformation':'代付信息',
		'remarks':'备注',
		'confirmOrder':'确认订单',
		'closeOrder':'关闭订单',
		'replenishmentConfirmationOrder':'补确认订单',
		'prompt':'系统无订单时，在此补单',
		'numberOfSupplementaryOrders':'补单数',
		'numberOfAdditionalOrders':'补增订单数',
		'numberOfCancellations':'撤单数',
		'supplementAmount':'补单金额',
		'additionalOrderAmount':'补增订单金额',
		'cancellationAmount':'撤单金额',
		'orderType':'订单类型',
		'reasonForReplenishment':'补单原因',
		'allSupplements':'所有补单',
		'supplementaryOrder':'正常补单',
		'additionalOrder':'补增订单',
		'cancelTheOrder':'撤单',
		'orderSucceeded':'订单完成',
		'waitingForPayment':'等待付款',
		'orderClosed':'订单关闭',
		'function':'功能',
		'handler':'操作人',
		'batchOrderNotSubmittedForProcessing':'批量提交未处理订单',
		'merchantNumber':'商家号',
		'merchantName':'商家名称',
		'pleaseEnterTheAmount':'请输入金额',
		'statisticsToday':'今日统计',
	},
	systemMenu:{
		'changePassword':'修改密码',
		'originalPassword':'原密码',
		'pleaseEnterOriginalPassword':'请输入原密码',
		'newPassword':'新密码',
		'pleaseEnterNewPassword':'请输入新密码',
		'confirmPassword':'确认密码',
		'pleaseEnterTheNewPasswordAgain':'请再次输入新密码',
		'googleVerificationCode':'谷歌验证码',
		'pleaseEntergoogleVerificationCode':'请输入谷歌验证码',
		'submit':'确定',
		'newlyAdded':'新增',
		'serialNumber':'序号',
		'userName':'用户名',
		'lastLoginTime':'最后登录时间',
		'role':'角色',
		'function':'功能',
		'resetUserPassword':'重置用户密码',
		'resetGoogleVerificationCode':'重置谷歌验证码',
		'deleteUser':'删除用户',
		'setRole':'设置角色',
		'deleteRole':'删除角色',
		'setPermissions':'设置权限',
		'noData':'暂无数据',
	},
	common:{
		'confirm':'确定执行此操作吗？',
		'cancelConfirm':'已取消执行此操作',
		'successConfirm':'执行成功',
		'prompt':'提示',
		'submit':'提交',
		'cancel':'取消',
	},
	warn:{
		'curName':'请输入币种名称',
		'currency':'请输入币种简称',
		'curRate':'请输入币种与USDT的汇率',
	},
	title: '测试',
}