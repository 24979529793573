import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/main.vue'
import Login from '../views/login/index.vue'
import Directory from '../views/index.vue' 


const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

//replace
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Main',
		component: Main,
		directory:false
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		directory:false,
		meta: {
			noNav:true
		},
	},
	{
		path: '/googleVerification',
		name: 'GoogleVerification',
		component: ()=>import('@/views/login/googleVerification.vue'),
		directory:false,
		meta: {
			noNav:true
		},
	},
	 {
		path: '/replace',
		name: 'Replace',
		component: ()=>import('@/views/replace.vue'),
		directory:false,
		meta: {
			noNav:true
		},
	},
	{
		path: '/backReportD',
		name: 'BackReportD',
		component: Directory,
		meta: {
			id:1,
			title: 'reportManagement', // 报表管理
			allShow:false,
			leven:0,
			parentId:0,
			islink:false,
			iconS: 'el-icon-s-marketing',
		},
		directory:true
	},
	// {
	// 	path: '/backReport',
	// 	name: 'BackReport',
	// 	component: () => import('@/views/report/backReport.vue'),
	// 	meta: {
	// 		id:2,
	// 		title: 'generalStatement', // 后台报表管理
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:1,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	{
		path: '/dsReport',
		name: 'DsReport',
		component: () => import('@/views/report/dsReport.vue'),		
		meta: {
			id:91,
			title: 'dsReport', // 代收报表
			allShow:false,
			leven:1,
			parentId:1,
			islink:true
		},
		directory:true
	},
	{
		path: '/dfReport',
		name: 'DfReport',
		component: () => import('@/views/report/dfReport.vue'),		
		meta: {
			id:92,
			title: 'dfReport', // 代付报表
			allShow:false,
			leven:1,
			parentId:1,
			islink:true
		},
		directory:true
	},
	// {
	// 	path: '/agenIncomeReport',
	// 	name: 'AgenIncomeReport',
	// 	component: () => import('@/views/report/agenIncomeReport.vue'),		
	// 	meta: {
	// 		id:7,
	// 		title: 'agentIncome', // 代理进账报表
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:1,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	// {
	// 	path: '/merchantIncomeReport',
	// 	name: 'MerchantIncomeReport',
	// 	component: () => import('@/views/report/merchantIncomeReport.vue'),
	// 	meta: {
	// 		id:9,
	// 		title: 'merchantIncome', // 商家进账报表
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:1,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	// {
	// 	path: '/merchantRateReport',
	// 	name: 'MerchantRateReport',
	// 	component: () => import('@/views/report/merchantRateReport.vue'),
	// 	meta: {
	// 		id:18,
	// 		title: 'merchantRate', // 商家费率报表
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:1,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	// {
	// 	path: '/passagewayReport',
	// 	name: 'PassagewayReport',
	// 	component: () => import('@/views/report/passagewayReport.vue'),		
	// 	meta: {
	// 		id:17,
	// 		title: 'collectionMethod', // 支付方式报表
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:1,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	// {
	// 	path: '/merchantOutReport',
	// 	name: 'MerchantOutReport',
	// 	component: () => import('@/views/report/merchantOutReport.vue'),
	// 	meta: {
	// 		id:27,
	// 		title: 'expenditureStatement', // 商家出账报表
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:1,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	// {
	// 	path: '/paywayReport',
	// 	name: 'PaywayReport',
	// 	component: () => import('@/views/report/paywayReport.vue'),		
	// 	meta: {
	// 		id:41,
	// 		title: 'passagewayStatement', // 通道报表
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:1,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	{
		path: '/paymentTypeD',
		name: 'PaymentTypeD',
		component: Directory,
		meta: {
			id:3,
			title: 'passagewayManagement',// 通道管理
			allShow:false,
			leven:0,
			parentId:0,			
			islink:false,
			iconS: 'el-icon-connection',
		},
		directory:true
	},
	// {
	// 	path: '/CurrentType',
	// 	name: 'CurrentType',
	// 	component: () => import('@/views/passagewayManage/currentType.vue'),
	// 	meta: {
	// 		id:45,
	// 		title: 'currencyManagement', // 币种管理
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:3,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	{
		path: '/blackList',
		name: 'BlackList',
		component: () => import('@/views/order/blackList'),
		meta: {
			id:52,
			title: 'blacklistManagement', // 黑名单管理
			allShow:false,
			leven:1,
			parentId:3,			
			islink:true
		},
		directory:true
	},
	{
		path: '/paymentType',
		name: 'PaymentType',
		component: () => import('@/views/passagewayManage/paymentType.vue'),
		meta: {
			id:4,
			title: 'collectionMethodManagement', // 支付方式管理
			allShow:false,
			leven:1,
			parentId:3,
			islink:true
		},
		directory:true
	},
	{
		path: '/accountManagerD',
		name: 'AccountManagerD',
		component: Directory,
		meta: {
			id:10,
			title: 'accountManagement', // 账号管理
			allShow:false,
			leven:0,
			parentId:0,
			islink:false,
			iconS: 'el-icon-s-custom',
			
		},
		directory:true
	},
	{
		path: '/agentAccount',
		name: 'AgentAccount',
		component: () => import('@/views/account/agentAccount/agentAccount'),
		meta: {
			id:11,
			title: 'agentManagement', // 代理管理
			allShow:false,
			leven:1,
			parentId:10,
			islink:true
		},
		directory:true
	},
	{
		path: '/traderAccount',
		name: 'TraderAccount',
		component: () => import('@/views/account/traderAccount/traderAccount'),
		meta: {
			id:12,
			title: 'merchantManagement', // 商户管理
			allShow:false,
			leven:1,
			parentId:10,
			islink:true
		},
		directory:true
	},
	{
		path: '/agentMoneyChange',
		name: 'AgentMoneyChange',
		component: () => import('@/views/account/traderAccount/agentBillReport'),
		meta: {
			id:48,
			title: 'agentMoneyChange', // 商户账变表
			allShow:false,
			leven:1,
			parentId:10,
			islink:true
		},
		directory:true
	},
	{
		path: '/traderMoneyChange',
		name: 'TraderMoneyChange',
		component: () => import('@/views/account/traderAccount/billReport'),
		meta: {
			id:47,
			title: 'merchantMoneyChange', // 商户账变表
			allShow:false,
			leven:1,
			parentId:10,
			islink:true
		},
		directory:true
	},
	// {
	// 	path: '/orderD',
	// 	name: 'OrderD',
	// 	component: Directory,
	// 	meta: {
	// 		id:13,
	// 		title: 'orderManagement', // 代收订单管理
	// 		allShow:false,
	// 		leven:0,
	// 		parentId:0,			
	// 		islink:false,
	// 		iconS: 'el-icon-s-order',
	// 	},
	// 	directory:true
	// },
	{
		path: '/payorderD',
		name: 'PayOrderD',
		component: Directory,
		meta: {
			id:21,
			title: 'payOrderManagement', // 代付订单管理
			allShow:false,
			leven:0,
			parentId:0,			
			islink:false,
			iconS: 'el-icon-s-order',
		},
		directory:true
	},
	{
		path: '/systemD',
		name: 'SystemD',
		component: Directory,
		meta: {
			id:15,
			title: 'systemSettings', // 系统设置
			allShow:false,
			leven:0,
			parentId:0,
			islink:false,
			iconS: 'el-icon-setting',
		},
		directory:true
	},
	{
		path: '/passagewaySysManage',
		name: 'PassagewaySysManage',
		component: () => import('@/views/passagewayManage/index'),	
		meta: {
			id:16,
			title: 'passagewaySystemManagement', // 通道系统管理
			allShow:false,
			leven:1,
			parentId:3,
			islink:true
		},
		directory:true
	},
	
	{
		path: '/order',
		name: 'Order',
		component: () => import('@/views/order'),
		meta: {
			id:19,
			title: 'collectionOrder', // 代收订单
			allShow:false,
			leven:1,
			parentId:13,			
			islink:true
		},
		directory:true
	},
	
	{
		path: '/abOrder',
		name: 'AbOrder',
		component: () => import('@/views/order/abOrder'),
		meta: {
			id:55,
			title: 'abOrder', // 异常代收订单
			allShow:false,
			leven:1,
			parentId:13,			
			islink:true
		},
		directory:true
	},
	// {
	// 	path: '/dorderPay',
	// 	name: 'OrderPay',
	// 	component: () => import('@/views/order/orderPay'),
	// 	meta: {
	// 		id:26,
	// 		title: 'paymentOrder', // 代付订单
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:21,			
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	{
		path: '/dorderHd',
		name: 'OrderHd',
		component: () => import('@/views/order/orderHd'),
		meta: {
			id:61,
			title: 'paymentOrder', // 话费订单
			allShow:false,
			leven:1,
			parentId:21,			
			islink:true
		},
		directory:true
	},
	{
		path: '/cancelOrder',
		name: 'CancelOrder',
		component: () => import('@/views/order/cancelOrder'),
		meta: {
			id:33,
			title: 'revokeOrder', // 撤销订单
			allShow:false,
			leven:1,
			parentId:13,			
			islink:true
		},
		directory:true
	},
	{
		path: '/scoreOrder',
		name: 'ScoreOrder',
		component: () => import('@/views/order/scoreOrder'),
		meta: {
			id:34,
			title: 'supplementRecord', // 补单记录
			allShow:false,
			leven:1,
			parentId:13,			
			islink:true
		},
		directory:true
	},
	{
		path: '/replenishment',
		name: 'Replenishment',
		component: () => import('@/views/order/replenishment'),
		meta: {
			id:31,
			title: 'supplementOrder', // 补增订单
			allShow:false,
			leven:1,
			parentId:13,			
			islink:true
		},
		directory:true
	},
	// {
	// 	path: '/morderPayReplenishment',
	// 	name: 'MOrderPayReplenishment',
	// 	component: () => import('@/views/order/replenishmentOrderPay'),
	// 	meta: {
	// 		id:51,
	// 		title: 'supplementOrderPay', // 补增订单
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:21,			
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	{
		path: '/updatePassword',
		name: 'updatePassword',
		component: () => import('@/views/system/index'),
		meta: {
			id:32,
			title: 'changePassword', // 修改密码
			allShow:false,
			leven:1,
			parentId:15,			
			islink:true
		},
		directory:true
	},
	// {
	// 	path: '/offlineOrder',
	// 	name: 'offlineOrder',
	// 	component: () => import('@/views/order/offlineOrder'),
	// 	meta: {
	// 		id:46,
	// 		title: 'offlineDepositOrder', // 线下入金订单
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:13,			
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	// {
	// 	path: '/tirunOrder',
	// 	name: 'TirunOrder',
	// 	component: () => import('@/views/order/tirunOrder'),
	// 	meta: {
	// 		id:36,
	// 		title: 'platformLubricationOrder', // 平台提润订单
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:13,			
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	// {
	// 	path: '/dailiTirunOrder',
	// 	name: 'DailiTirunOrder',
	// 	component: () => import('@/views/order/dailitirunOrder'),
	// 	meta: {
	// 		id:38,
	// 		title: 'agentLubricationOrder', // 代理提润订单
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:13,			
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	{
		path: '/usdtTrun',
		name: 'UsdtTrun',
		component: () => import('@/views/order/usdtTrun.vue'),
		meta: {
			id:44,
			title: 'transferPlatformProfit', // 平台利润划转
			allShow:true,
			leven:1,
			parentId:13,
			islink:true
		},
		directory:false
	},
	{
		path: '/Tirun',
		name: 'Tirun',
		component: () => import('@/views/order/tirun'),
		meta: {
			id:37,
			title: 'platformLubrication', // 平台提润
			allShow:false,
			leven:1,
			parentId:13,			
			islink:true
		},
		directory:false
	},
	
	{
		path: '/userSys',
		name: 'UserSys',
		component: () => import('@/views/system/userSys.vue'),
		meta: {
			id:39,
			title: 'userManagement', // 用户管理
			allShow:false,
			leven:1,
			parentId:15,
			islink:true
		},
		directory:true
	},
	{
		path: '/characterSys',
		name: 'CharacterSys',
		component: () => import('@/views/system/characterSys.vue'),
		meta: {
			id:40,
			title: 'roleManagement', // 角色管理
			allShow:false,
			leven:1,
			parentId:15,
			islink:true
		},
		directory:true
	},
	{
		path: '/operationLog',
		name: 'OperationLog',
		component: () => import('@/views/system/operationLog.vue'),
		meta: {
			id:59,
			title: 'operationLog', // 操作日志
			allShow:false,
			leven:1,
			parentId:15,
			islink:true
		},
		directory:true
	},
	{
		path: '/index',
		name: 'Index',
		component: () => import('@/views/index.vue'),
		meta: {
			id:42,
			title: 'allData', // 数据总览
			allShow:false,
			leven:0,
			parentId:0,
			islink:true
		},
		directory:false
	},
	{
		path: '/telegram',
		name: 'Telegram',
		component: Directory,
		meta: {
			id:14,
			title: 'telegram', // telegram管理
			allShow:false,
			leven:0,
			parentId:0,			
			islink:false,
			iconS: 'el-icon-s-promotion',
		},
		directory:true
	},
	{
		path: '/telegramCon',
		name: 'TelegramCon',
		component: () => import('@/views/telegram/telegramCon.vue'),
		meta: {
			id:58,
			title: 'botManagement', // 通道群组管理
			allShow:false,
			leven:1,
			parentId:14,
			islink:true,
			iconS: 'el-icon-s-promotion',
		},
		directory:true
	},
	{
		path: '/traTelegramChat',
		name: 'TraTelegramChat',
		component: () => import('@/views/telegram/traTelegramChat.vue'),
		meta: {
			id:53,
			title: 'merchantChatManagement', // 商户群组管理
			allShow:false,
			leven:1,
			parentId:14,
			islink:true
		},
		directory:true
	},
	{
		path: '/paywayTelegramChat',
		name: 'PaywayTelegramChat',
		component: () => import('@/views/telegram/paywayTelegramChat.vue'),
		meta: {
			id:57,
			title: 'passagewayChatManagement', // 通道群组管理
			allShow:false,
			leven:1,
			parentId:14,
			islink:true
		},
		directory:true
	},
	{
		path: '/telegramNotice',
		name: 'TelegramNotice',
		component: () => import('@/views/telegram/telegramNotice.vue'),
		meta: {
			id:54,
			title: 'noticeManagement', // 通知消息列表
			allShow:false,
			leven:1,
			parentId:14,
			islink:true
		},
		directory:true
	},
	// {
	// 	path: '/usdtOrder',
	// 	name: 'UsdtOrder',
	// 	component: () => import('@/views/order/usdtOrder.vue'),
	// 	meta: {
	// 		id:43,
	// 		title: 'USDT订单',
	// 		allShow:true,
	// 		leven:1,
	// 		parentId:13,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	// {
	// 	path: '/taobaoOrder',
	// 	name: 'TaobaoOrder',
	// 	component: () => import('@/views/order/taobaoOrder.vue'),
	// 	meta: {
	// 		id:43,
	// 		title: '商户代付',
	// 		allShow:true,
	// 		leven:1,
	// 		parentId:13,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	
] 

const router = new VueRouter({
	linkActiveClass: "activeClass",
	/*添加默认样式 activeClass   activeClass 写在组件样式里面   */
	routes
})

export default router
